
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: '404Page',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    // onBeforeMount(() => {})

    const data = reactive({
      onReturn () {
        router.push('/')
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
